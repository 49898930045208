import React from 'react'
import {
  LinkedinShareButton,
  TwitterShareButton,
  FacebookShareButton,
} from "react-share";
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FiShare } from 'react-icons/fi';



const Share = ({ title, url, text, className }) => {
  return (
    <div className={`Share align-text-top ${className}`}>
      <TwitterShareButton url={url} className="ml-1 opacity-50 hover:opacity-100">
        <FaTwitter />
      </TwitterShareButton>
      <LinkedinShareButton url={url} className="ml-1 opacity-50 hover:opacity-100">
        <FaLinkedin />
      </LinkedinShareButton>
      <FacebookShareButton url={url} className="ml-1 opacity-50 hover:opacity-100">
        <FaFacebook />
      </FacebookShareButton>

      { typeof window !== `undefined` && navigator.share ? (
        <a className="ShareButton inline-block cursor-pointer ml-1 opacity-50 hover:opacity-100" onClick={() => {navigator.share({title: title, url: url})}}>
          <FiShare />
        </a>
      ) : null }
    </div>
  )
}

export default Share

